import React  from "react";
import { Helmet } from "react-helmet";
import BenefitsGrid from "../../components/BenefitsGrid";
import Layout from "../../components/layout";
import * as baseStyles from "../index.module.css";
import * as styles from "./leases.module.css";

// images
import paymentGateway from "../../images/payment-gateway.jpg";
import virtualTerminal from "../../images/virtual-terminal.jpg"

export default function Leases() {
  return (
    <Layout>
      <Helmet>
        <title>Leases | MyEZSolutions</title>
      </Helmet>
      
      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Leases</h1>
          <p>We understand that new businesses have limited cash availability, and sometimes it makes more sense to finance a capital expenditure over several years. <br/> Our low interest rate rent-to-own plans provide the flexibility you need to invest in your business without draining your cash balance.</p>
        </div>
        
        <div className={styles.whyOnlineParent}>
          <div className={styles.whyOnlineHead}>
            My EZ Solutions offers affordable and flexible leasing options for Terminal and POS purchases. We understand that new businesses have limited cash availability, and sometimes it makes more sense to finance a capital expenditure over several years. Our low interest rate rent-to-own plans provide the flexibility you need to invest in your business without draining your cash balance.
          </div>
          <h4 className={styles.whyOnlineTagline}>AFFORDABLE AND ACCESSIBLE LEASING</h4>
          <div className={styles.onlineBenefits}>
            <div className={styles.whyOnlineBenefit}>
              <div className={styles.whyOnlineImage}><img src={paymentGateway}
                                                          alt="Estimated monthly payments"/></div>
              <div className={styles.whyOnlineDescription}>
                <h4>Estimated Monthly Payments
                </h4>
                <p>Every business is different and monthly payments are dependent on company-specific factors. You can also apply in a few easy steps.
                  <br/><br/>
                  Most leases require the first and last month's payment, which is due at closing, but we do offer other flexible plans. Exact leasing terms are determined by an individual business' credit check. Contact us today to learn more.
                </p>
              </div>
            </div>
            <div className={styles.whyOnlineBenefit}>
              <div className={styles.whyOnlineImage}><img src={virtualTerminal}
                                                          alt="The value of leasing"/></div>
              <div className={styles.whyOnlineDescription}>
                <h4>The Value of Leasing
                </h4>
                <p>Leasing provides a great financing option for companies with limited cash flow. Looking at software with monthly payment options? These may sound great, but they still require an upfront hardware purchase and the payments last forever — you never actually own your software. Leasing provides the best of both worlds by eliminating the upfront hardware purchase and by eliminating the perpetual payments. Once your lease is paid off, you own everything and you don't owe any further monthly payments.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <BenefitsGrid title={"We're here to make it EZ!"} items={[]}/>
      </div>
    </Layout>
  );
}